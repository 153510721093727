import { apiDelete, ApiError, apiGet, apiPatch, apiPost, apiPut } from 'App/Dataman';
import { API_INTERFACE_USER_SESSIONS } from './endpoints';
import UserSession from '@/models/UserSession';

async function createUserSession(module, data = {}) {
  console.log(data);
  const res = await apiPost(API_INTERFACE_USER_SESSIONS, { data: { module, ...data } });
  if (res.status === 201) {
    return res.data;
  } else {
    throw new ApiError(res.statusText, res);
  }
}

async function getUserSessionList(params, mapToModel = true) {
  const res = await apiGet(API_INTERFACE_USER_SESSIONS, params);
  if (res.status === 200) {
    if (mapToModel) {
      return res.data.map((props) => new UserSession(props));
    }
    return res.data;
  } else {
    throw new ApiError(res.statusText, res);
  }
}

async function updateUserSession(id, data) {
  const res = await apiPatch(`${API_INTERFACE_USER_SESSIONS}/${id}`, { data });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new ApiError(res.statusText, res);
  }
}

async function replaceUserSession(id, data) {
  const res = await apiPut(`${API_INTERFACE_USER_SESSIONS}/${id}`, { data });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new ApiError(res.statusText, res);
  }
}

async function deleteUserSession(id) {
  const res = await apiDelete(`${API_INTERFACE_USER_SESSIONS}/${id}`);
  if (res.status === 204) {
    return true;
  } else {
    throw new ApiError(res.statusText, res);
  }
}

export const userSessionsAPI = {
  create: createUserSession,
  getList: getUserSessionList,
  update: updateUserSession,
  replace: replaceUserSession,
  delete: deleteUserSession,
};
