import FilterItem from '../FilterItem';

class FilterSingle extends FilterItem {
  constructor(conf, subcategory) {
    super(conf, subcategory);
  }

  collectValues() {
    let value = {};
    if (this.isNA) {
      value = this.naFilter;
    } else {
      value[this.property] = this.parseQueryString(this.value);
    }
    return value;
  }

  resetValue = () => {
    this.value = null;
  };

  parseQueryString(inputString) {
    if (this.splittable) {
      if (!/[,\s;]/.test(inputString)) {
        return inputString;
      }
      const resultArray = inputString.split(/[,\s;]/);
      return resultArray.filter((str) => str.trim() !== '');
    } else {
      return inputString;
    }
  }
}

export default FilterSingle;
