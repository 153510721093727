export const FEAT_ID_HEATER_MATERIAL = 5;
export const FEAT_ID_FILLING_MATERIAL = 14;
export const FEAT_ID_WEIGHT_G = 31;
export const FEAT_ID_REGION = 30;
export const FEAT_ID_PACKAGE_MATERIAL = 37;
export const FEAT_ID_INGREDIENTS = 95;
export const FEAT_ID_DESIRED_EFFECTS = 102;
export const FEAT_ID_FLAVORS = 105;
export const FEAT_ID_ENERGY = 106;
export const FEAT_ID_FAT = 108;
export const FEAT_ID_CARBOHYDRATES = 109;
export const FEAT_ID_CONTAINER_CAPACITY = 111;
export const FEAT_ID_INTENDED_SERVINGS_PER_SKU = 112;
export const FEAT_ID_PACKAGE_OPTIONS = 117;
export const FEAT_ID_PROTEINS = 119;
export const FEAT_ID_WEIGHT_OZ = 122;
export const FEAT_ID_SUGAR = 141;
export const FEAT_ID_PACKAGE_TYPES = 177;
// export const FEAT_ID_ = ;

export const TYPE_ID_USP = 36;
export const TYPE_COMPETITORS_ID = 279;
export const TYPE_ID_IMAGES = 297;

export const FEAT_GROUP_FLAVORS_AND_EFFECTS = [FEAT_ID_FLAVORS, FEAT_ID_DESIRED_EFFECTS];
export const FEAT_GROUP_NP = [FEAT_ID_ENERGY, FEAT_ID_FAT, FEAT_ID_PROTEINS, FEAT_ID_CARBOHYDRATES, FEAT_ID_SUGAR];
export const FEAT_GROUP_PACKAGE = [
  FEAT_ID_CONTAINER_CAPACITY,
  FEAT_ID_WEIGHT_G,
  FEAT_ID_WEIGHT_OZ,
  FEAT_ID_PACKAGE_MATERIAL,
  FEAT_ID_PACKAGE_TYPES,
  FEAT_ID_PACKAGE_OPTIONS,
];

export default {
  FEAT_ID_FILLING_MATERIAL,
  FEAT_ID_REGION,
  FEAT_ID_HEATER_MATERIAL,
  TYPE_ID_USP,
  TYPE_COMPETITORS_ID,
  TYPE_ID_IMAGES,
};
