import {
  FT_LIST,
  FT_SINGLE,
  FT_DATE,
  FT_CHECKBOX,
  FT_AUTOCOMPLETE,
  FT_RANGE,
  FT_SUGGESTION,
  FT_CONDITION_GROUP,
} from '../FilterTypes';
import { CAT_COMPANIES } from 'App/utils/Collections';
import { mediaResourceTypes } from 'Models/form-models/collections';
import {
  searchBrand,
  loadLocations,
  loadCompanyFileGroups,
  loadActivitySpheres,
  suggestCompany,
  loadCountryRegions,
  companyTypesLoader,
  searchCompanyTypes,
} from './loaders';
import { ROLE_ADMIN, ROLE_STAFF } from '@/app/constants/Roles';
import { TYPE_COMPETITORS_ID } from '@/lib/enums/HardcodedIds';
import { getCategoryResource } from '@/components/filters/config/helpers/resources';

const SPLITTABLE_HINT = 'You can type multiple values separated by comma, semicolon, or space.';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 3,
  },
  companies_activity: {
    name: 'Companies Activity',
    groupOrderIndex: 0,
  },
  date: {
    name: 'Date',
    groupOrderIndex: 1,
  },
  files: {
    name: 'Files',
    groupOrderIndex: 2,
  },
  products: {
    name: 'Products',
    groupOrderIndex: 4,
  },
  competitors: {
    name: 'Competitors',
    groupOrderIndex: 5,
  },
};

export default {
  category: CAT_COMPANIES,
  resources: [getCategoryResource({ id: 'categories', group: groups.general, role: [ROLE_STAFF, ROLE_ADMIN] })],
  filters: [
    {
      id: 'and_group_or_manufacturedProducts.types',
      propertyName: 'and_group_or_manufacturedProducts.types',
      label: 'Product Type',
      group: groups.products,
      type: FT_CONDITION_GROUP,
      placeholder: 'Choose type',
      fieldConf: {
        searcher: searchCompanyTypes,
        items: companyTypesLoader,
      },
    },
    {
      id: 'manufacturedProducts.appeared',
      label: 'Product Appeared Date',
      group: groups.products,
      type: FT_DATE,
    },
    {
      id: 'tradeBrands.id',
      label: 'Trade brand',
      type: FT_AUTOCOMPLETE,
      group: groups.products,
      fieldConf: {
        searcher: searchBrand,
        items: searchBrand,
      },
    },
    {
      id: 'manufacturedProducts.brands.id',
      label: 'Manufactured brand',
      type: FT_AUTOCOMPLETE,
      group: groups.products,
      fieldConf: {
        searcher: searchBrand,
        items: searchBrand,
      },
    },
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      splittable: true,
      hint: SPLITTABLE_HINT,
    },
    {
      id: 'name',
      label: 'Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'location',
      label: 'Location',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadLocations,
    },
    {
      id: 'activitySpheres',
      label: 'Spheres of activity',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadActivitySpheres,
    },
    {
      id: 'createdAt',
      label: 'Created',
      group: groups.date,
      type: FT_DATE,
    },
    {
      id: 'exists[files]',
      label: 'Contains Files',
      fieldConf: {
        label: 'Show items with files',
      },
      group: groups.files,
      type: FT_CHECKBOX,
      defaultValue: () => true,
    },
    {
      id: 'files.fileGroup',
      label: 'Groups',
      group: groups.files,
      type: FT_LIST,
      getListItems: loadCompanyFileGroups,
    },
    {
      id: 'registrationNumber',
      label: 'Registration Number',
      group: groups.general,
      type: FT_SINGLE,
      hint: '',
    },
    {
      id: 'mediaResources.mediaResourceType.id',
      label: 'Media resource',
      group: groups.companies_activity,
      type: FT_LIST,
      getListItems: mediaResourceTypes,
    },
    {
      id: 'region.id',
      label: 'State',
      group: groups.companies_activity,
      type: FT_LIST,
      getListItems: loadCountryRegions,
    },
    {
      id: 'foundationYear',
      label: 'Foundation year',
      group: groups.date,
      type: FT_RANGE,
      isLteGte: true,
    },
    {
      id: 'patents.id',
      label: 'Patents',
      group: groups.general,
      type: FT_LIST,
      hide: true,
    },
    {
      id: 'tradeBrands.products.id',
      label: 'Trade products',
      group: groups.general,
      type: FT_LIST,
      hide: true,
    },
    {
      id: 'manufacturedProducts.id',
      label: 'Manufactured products',
      group: groups.general,
      type: FT_LIST,
      hide: true,
    },
    {
      id: 'parentCompany.name',
      label: 'Parent Company',
      group: groups.companies_activity,
      type: FT_SUGGESTION,
      getSuggestions: suggestCompany,
    },
    {
      id: 'parentCompany.id',
      label: 'Parent Company ID Number',
      type: FT_SINGLE,
      group: groups.companies_activity,
      splittable: true,
      hint: SPLITTABLE_HINT,
    },
    {
      id: 'competitors',
      propertyName: 'and_group_or_types',
      label: 'Names',
      group: groups.competitors,
      type: FT_CONDITION_GROUP,
      roles: [ROLE_STAFF],
      placeholder: 'Choose type',
      fieldConf: {
        searcher: (term) => searchCompanyTypes(term, TYPE_COMPETITORS_ID),
        items: () => companyTypesLoader(TYPE_COMPETITORS_ID),
      },
    },
  ],
  searchBarProp: 'complex_name',
};
