import { loadCategories } from '@/components/filters/config/loaders';
import { FT_LIST } from '@/components/filters/FilterTypes';

/**
 * @param {object} conf
 * @param {string} [conf.id]
 * @param {string} [conf.label]
 * @param {Object} [conf.group]
 */
export const getCategoryResource = (conf = {}) => {
  return {
    name: 'commonCategories',
    loader: async () => {
      const categories = await loadCategories();
      return [
        {
          id: 'categories',
          label: 'Categories',
          ...(conf || {}),
          type: FT_LIST,
          valuesList: [...categories],
          hide: categories.length < 2,
        },
      ];
    },
  };
};
