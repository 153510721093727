import Vue from 'vue';
import Axios from 'axios';
import vuetify from 'App/plugins/vuetify';
import toaster from 'App/plugins/toaster';
import router from 'App/router';
import store from 'App/store/vuex';
import App from './App.vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import FallbackImg from 'App/fallback-img-directive';
import { i18n } from 'App/plugins/i18n';
import { Trans } from 'App/plugins/Translation';
import FilterBox from 'Components/filters/FilterBox';
import CommonMixin from 'App/mixins/CommonMixin';
import config from '../project.config';
import Api from 'Lib/Api';
import ApiMapper from 'Lib/ApiMapper';
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';
import './scss/common.scss';
import { getInstanceUID } from 'Lib/Utils';

const mode = process.env.VUE_APP_NODE_ENV;
Vue.directive('observe-visibility', ObserveVisibility);
Vue.config.productionTip = false;
Vue.component('ItemsTable', () => import('Components/ItemsTable'));
Vue.directive('fallback-img', FallbackImg);
Vue.mixin(CommonMixin);
Vue.prototype.$http = Axios;
const apiClient = new Api(config.api, store);
Vue.prototype.$client = apiClient;
Vue.prototype.$api = new ApiMapper(apiClient, MODULES_MAP);
Vue.prototype.$fbx = new FilterBox(router, store, mode);
Vue.prototype.$buildTimestamp = document.querySelector('html')?.dataset?.buildTimestamp;
Vue.prototype.$envMode = mode;
Vue.prototype.$UID = getInstanceUID();

const tab = store.state.appTabs.get(Vue.prototype.$UID);
if (tab) {
  if (tab.active) {
    Vue.prototype.$UID = getInstanceUID(true);
  } else {
    tab.active = true;
  }
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  toaster,
  render: (h) => h(App),
}).$mount('#app');

Trans.changeLanguage(store.state.lang).then((lang) => {
  let locale = lang;
  if (lang === 'zh') {
    locale = 'zhHans';
  }
  vuetify.framework.lang.current = locale;
});
