import { FT_LIST, FT_SINGLE } from '../FilterTypes';
import { CAT_FEATURES } from 'App/utils/Collections';
import { loadProductTypes } from './loaders';
import { getCategoryResource } from '@/components/filters/config/helpers/resources';

const SPLITTABLE_HINT = 'You can type multiple values separated by comma, semicolon, or space.';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
};

export default {
  category: CAT_FEATURES,
  resources: [getCategoryResource({ id: 'categories.id', group: groups.general })],
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      splittable: true,
      hint: SPLITTABLE_HINT,
    },
    {
      id: 'translations.name',
      label: 'Name',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'types.id',
      label: 'Types',
      group: groups.general,
      type: FT_LIST,
      getListItems: loadProductTypes,
    },
  ],
  searchBarProp: 'translations.name',
};
