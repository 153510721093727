import { CAT_NEWS } from 'App/utils/Collections';
import { getCategoryResource } from '@/components/filters/config/helpers/resources';
import { ROLE_ADMIN, ROLE_STAFF } from '@/app/constants/Roles';

export const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 10,
  },
};

export default {
  category: CAT_NEWS,
  resources: [getCategoryResource({ id: 'categories', group: groups.general, role: [ROLE_STAFF, ROLE_ADMIN] })],
  filters: [],
};
