/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes.js';
import store from '../store/vuex';
import { sleepAsync } from '@/lib/Utils';

Vue.use(VueRouter);

function hasToken() {
  return Boolean(localStorage.getItem('wims_token'));
}

const router = new VueRouter({ routes, mode: 'history' });

router.beforeEach(async (to, from, next) => {
  const { query, meta, path } = to;
  const logged = hasToken();
  const isPublicRedirect = /^\/(reset-password\/[a-z0-9]{40,}|prices|\d+)$/i.test(query.r);

  if (isPublicRedirect && !logged) {
    next({ path: query.r });
    return;
  }
  if (!logged && !meta.isPublic) {
    next({ path: '/', query: { r: path } });
    return;
  }
  if (!logged && meta.isPublic) {
    next();
    return;
  }
  if (logged && query.r) {
    next({ path: query.r });
    return;
  }

  while (!store.state.flags.loaded) {
    await sleepAsync(10);
  }
  const { isPublic, restrictions, restriction: role, redirectPage } = to.meta;
  const roles = role ? [role] : restrictions || [];
  const restricted = isRestricted(roles) || isParamRestricted(to, restrictions);
  if (restricted && !isPublic) {
    next('/403');
  }
  next();
});

export default router;

function isParamRestricted(route, restrictions) {
  if (!restrictions) {
    return false;
  }
  const { param, map } = restrictions;
  const { [param]: key } = route.params;
  if (!key) {
    return false;
  }
  const roles = Array.isArray(map[key]) ? map[key] : [map[key]];
  return !roles.some((role) => {
    if (Array.isArray(role)) {
      return role.every((item) => store.state.user.hasAccess(item));
    }
    return store.state.user.hasAccess(role);
  });
}

function isRestricted(roles) {
  if (!roles?.length) {
    return false;
  }
  return !roles.some((role) => store.state.user.hasAccess(role));
}
