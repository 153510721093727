import FilterItem from '../FilterItem';
import FilterListStorage from './FilterListStorage';

class FilterConditionGroup extends FilterItem {
  valuesStorage = null;

  constructor(conf, subcategory) {
    super(conf, subcategory);
    this.value = [this.#makeGroup()];
    this.groups = [this.#makeGroup()];
    this.valuesStorage = new FilterListStorage();
  }

  setValuesList(valuesList) {
    this.valuesStorage.setValuesList(valuesList);
  }

  get valuesList() {
    return this.valuesStorage.valuesList;
  }

  getValueLabel = (id) => {
    return this.valuesStorage.getValueLabel(id);
  };

  resetValue = () => {
    this.value = [this.#makeGroup()];
    this.groups = [this.#makeGroup()];
  };

  async apply(groups) {
    this.groups = [];
    groups.forEach((group) => {
      if (group === false) {
        this.na = true;
        return;
      }
      this.groups.push(this.#makeGroup(group));
    });
    const items = await this.fieldConf.items();
    this.valuesStorage.setValuesList(items);
  }

  collectValues() {
    const filter = {};
    if (this.isFeature) {
      filter.__feature = this.#collectFeatures();
    } else {
      return this.#gatherValues();
    }
    return filter;
  }

  #gatherValues() {
    const filter = {};
    if (this.na) {
      const prop = this.options.naProp || this.property;
      return {
        [prop]: false,
      };
    }
    let i = 0;
    for (const group of this.groups) {
      if (group.items.length === 0) {
        continue;
      }
      const prop = `${this.property}[${i + 1}]`;
      filter[prop] = group.items.map((item) => item.value);
      if (group.notValue) {
        filter[prop].push('not');
      }
      i++;
    }
    return filter;
  }

  #collectFeatures() {
    const { featureID } = this;
    if (this.isNA) {
      return { [featureID]: null, not: true };
    }
    const features = [];
    for (const group of this.groups) {
      const values = group.items.map(({ value }) => value).filter(Boolean);
      if (values.length === 0) {
        continue;
      }
      const entry = { [featureID]: values };
      if (group.notValue) {
        entry.not = true;
      }
      features.push(entry);
    }
    return features;
  }

  getConditionsString(value) {
    let groups = value.map((group) => {
      return {
        notValue: group.notValue,
        items: [...group.items.map((item) => this.valuesStorage.getValueLabel(item.value))],
      };
    });
    groups = groups.map((group) => ({ notValue: group.notValue, items: group.items.filter((item) => item !== 'id:') }));
    groups = groups.filter((group) => group.items.length);
    const joinedGroups = groups
      .map((group) => `${group.notValue ? ' NOT ' : ''}(${group.items.join(' OR ')})`)
      .join(' AND ');
    return joinedGroups;
  }

  addGroup() {
    this.groups.push(this.#makeGroup());
  }

  addEmptyFieldToGroup(groupID) {
    const index = this.#findGroupIndexByID(groupID);
    if (index !== -1) {
      this.groups[index].items.push({ id: this.#makeID(), value: '' });
    }
  }

  isLastGroup(groupID) {
    return this.#findGroupIndexByID(groupID) === this.groups.length - 1;
  }

  getCellValue(groupID, cellID) {
    const groupIndex = this.#findGroupIndexByID(groupID);
    if (groupIndex < 0) {
      return null;
    }
    const cellIndex = this.#findCellIndex(groupIndex, cellID);
    return this.groups[groupIndex]?.items[cellIndex]?.value || null;
  }

  getGroupNOTValue(groupID) {
    const groupIndex = this.#findGroupIndexByID(groupID);
    if (groupIndex < 0) {
      return false;
    }
    return this.groups[groupIndex]?.notValue;
  }

  changeCellValue(groupID, cellID, value) {
    const groupIndex = this.#findGroupIndexByID(groupID);
    if (groupIndex === -1) {
      return;
    }
    const cellIndex = this.#findCellIndex(groupIndex, cellID);
    if (value === null) {
      this.groups[groupIndex].items.splice(cellIndex, 1);
      this.groups = this.groups.filter((group) => group.items.length !== 0);
      return;
    }
    this.groups[groupIndex].items[cellIndex].value = value;
  }

  changeGroupNOTValue(groupID, value) {
    const groupIndex = this.#findGroupIndexByID(groupID);
    if (groupIndex === -1) {
      return;
    }
    this.groups[groupIndex].notValue = value;
  }

  #makeGroup(values = []) {
    let notValue = false;
    if (values.length) {
      const items = Array(values.length);
      values.forEach((value, index) => {
        items[index] = { id: this.#makeID(), value };
      });
      let notIndex = items.findIndex((element) => element.value === 'not');
      if (notIndex >= 0) {
        notValue = true;
        items.splice(notIndex, 1);
      }
      return {
        notValue: notValue,
        id: this.#makeID(),
        items: items,
      };
    }
    return {
      notValue: notValue,
      id: this.#makeID(),
      items: [{ id: this.#makeID(), value: '' }],
    };
  }

  #makeID() {
    const id = Math.floor(Date.now() * Math.random());
    return id;
  }

  #findGroupIndexByID(id) {
    return this.groups.findIndex((item) => item.id === id);
  }

  #findCellIndex(groupIndex, itemID) {
    return this.groups[groupIndex].items.findIndex((item) => item.id === itemID);
  }
}

export default FilterConditionGroup;
